<template>
  <div class="container">
    <b-row>
      <b-col lg="8">
        <article class="post-area single-blog">
          <div class="about-me">
            <h1>Pogoji uporabe in Pravilnik o varstvu osebnih podatkov</h1>

            <div class="my-5">
              <div class="mb-2">
                <router-link
                    :to="{ name: 'TermsAndConditions', hash: '#pogoji-uporabe' }"
                >Pogoji uporabe
                </router-link
                >
              </div>
              <div>
                <router-link
                    :to="{
                    name: 'TermsAndConditions',
                    hash: '#pravilnik-o-varstvu-osebnih-podatkov'
                  }"
                >Pravilnik o varstvu osebnih podatkov
                </router-link
                >
              </div>
            </div>

            <h2 id="pogoji-uporabe" name="pogoji-uporabe">POGOJI UPORABE</h2>
            <h3>Preambula</h3>
            <p>
              Zahvaljujemo se vam za registracijo v aplikacijo Zapisi spomina. V
              teh Pogojih uporabe so navedena pravila in omejitve, ki veljajo
              pri vaši uporabi aplikacije, zlasti pri nalaganju vsebin vanjo.
              Pred začetkom uporabe temeljito preberite te Pogoje uporabe. Za
              dodatna vprašanja se obrnite na Zavod Dobra pot
              (info@dobra-pot.si).
            </p>
            <p>
              Ti Pogoji uporabe so zavezujoč sporazum, ki ureja uporabo
              aplikacije Zapisi spomina med vami in Zavodom Dobra pot kot
              avtorjem in lastnikom ter upravljavcem aplikacije in podatkov
              Zapisi spomina.
            </p>
            <p>
              Sporazum je sklenjen, ko v svoj elektronski predal prejmete
              potrditveno sporočilo o registraciji v aplikacijo. Vaša
              registracija v aplikacijo Zapisi spomina pomeni, da se strinjate s
              Pogoji uporabe aplikacije Zapisi spomina in sklenete sporazum o
              tem z Zavodom Dobra pot.
            </p>
            <p>Za registrirane uporabnike, ki v aplikacijo vnašajo vsebino (zapisovalce spominov) sprejem teh Pogojev
              uporabe predstavlja dogovor o sodelovanju med Zavodom Dobra pot kot prostovoljsko organizacijo in
              uporabnikom kot prostovoljcem.</p>
            <p>
              Aplikacija Zapisi spomina je poglavitno namenjena beleženju živega
              ustnega izročila ljudi, zlasti starejših, in njihovih spominov o
              življenju nekoč. Vsebine se beležijo z namenom, da se ohranijo za
              danes in jutri.
            </p>

            <h3>1. ČLEN – Uporabniki, registracija, geslo</h3>
            <p>
              Registracija v aplikacijo Zapisi spomina je brezplačna in
              prostovoljna. Samo branje vsebin v aplikaciji ne zahteva
              registracije. Registrirani uporabniki z registracijo dobijo
              možnost objavljanja vsebin v aplikaciji.
            </p>
            <p>
              Za registracijo mora uporabnik navesti svoj veljaven elektronski
              naslov, ime in priimek, psevdonim oz. prikazno ime, letnico
              rojstva in geslo, po želji tudi opis in telefonsko številko.
            </p>
            <p>
              Če je uporabnik mladoletna oseba, mora poleg svojega veljavnega
              elektronskega naslova, imena in priimka, psevdonima oz. prikaznega
              imena, letnice rojstva ter gesla navesti tudi ime in priimek
              zakonitega zastopnika (starša ali skrbnika), ki soglaša z njegovo
              registracijo ter njegov veljaven elektronski naslov.
            </p>
            <p>
              Uporabniški profil v aplikaciji se lahko ustvari tudi v imenu
              organizacije, društva, institucije ipd. Za takšne uporabnike enako
              veljajo vsa pravila in pogoji, navedeni v tem dokumentu, z
              dodatkom, da mora imeti oseba, ki ustvari uporabniški račun v
              imenu organizacije, društva, institucije ipd. za to soglasje oz.
              dovoljenje zakonitega zastopnika te organizacije, društva,
              institucije ipd.
            </p>
            <p>
              Uporabnik se zavezuje, da bo ob registraciji navedel točne,
              popolne in posodobljene podatke o sebi. Uporabnik ne sme
              uporabljati prikaznega oziroma uporabniškega imena, za katerega
              nima pravice uporabljati ali imena druge osebe z namenom
              posnemanja druge osebe. Uporabniški račun v aplikaciji ni
              prenosljiv.
            </p>
            <p>
              Geslo je vezano na posameznega uporabnika, ni prenosljivo in ga
              mora uporabnik zaščititi pred dostopom tretjih oseb in s tem pred
              zlorabo.
            </p>
            <p>
              Uporabnik z registracijo soglaša s tem, da upravljavec aplikacije
              uporablja uporabnikove poslane osebne podatke za namene
              upravljanja aplikacije in vsebin v njej.
            </p>
            <p>
              Z registracijo v aplikacijo se uporabnik strinja, da na svoj
              elektronski naslov prejme obvestila o vsaki spremembi statusa
              njegovega zapisa v aplikaciji. Ta obvestila lahko po želji kadar
              koli izklopi v nastavitvah svojega uporabniškega računa.
            </p>
            <p>
              Z registracijo v aplikacijo se uporabnik strinja tudi, da se
              njegov elektronski naslov uvrsti na seznam prejemnikov
              elektronskih novic o drugih aktivnostih upravljavca aplikacije. Od
              prejemanja teh obvestil se lahko odjavi s klikom ustrezne povezave
              (Odjava/Unsubscribe) na dnu vsakokratnega elektronskega sporočila.
            </p>

            <h3>2. ČLEN – Namen uporabe</h3>
            <p>
              Aplikacija Zapisi spomina je poglavitno namenjena beleženju živega
              ustnega izročila ljudi, zlasti starejših, in njihovih spominov o
              življenju nekoč. Vsebine se beležijo z namenom, da se ohranijo za
              danes in jutri.
            </p>
            <p>
              Registrirani uporabniki, ki v aplikacijo vnašajo vsebino, morajo
              slednjo vnesti v skladu s poglavitnim namenom aplikacije, po
              načelu spoštljivosti do samega izročila in oseb, ki so
              izpričevalci/nosilci izročila ter tudi do drugih bralcev zapisov
              oz. uporabnikov aplikacije.
            </p>
            <p>
              V aplikaciji ni dovoljeno objavljati vsebin, katerih namen je
              promocija določenega pravnega subjekta ali fizične osebe,
              privabljanje strank, ponujanje svojih storitev, izdelkov in
              podobno.
            </p>
            <p>
              Vsak uporabnik odgovarja za svoja dejanja in objave. Avtor in
              upravljavec aplikacije, Zavod Dobra pot, v nobenem primeru ne
              odgovarja za vsebino zapisov, objavljenih v aplikaciji.
            </p>
            <p>
              Uporabnik je odgovoren za resničnost in etnološko relevantnost
              vsebine, ki jo objavlja v aplikaciji.
            </p>
            <p>
              Vsi zapisi uporabnikov se ob oddaji pošljejo v pregled in
              potrditev upravljavcu. Zavod Dobra pot si pridržuje pravico do
              moderiranja ali brisanja uporabniških zapisov po lastni presoji.
            </p>

            <h3>3. ČLEN – Uporabniški vnosi – zapisi</h3>
            <p>
              Naslovi objav oz. zapisov morajo čim bolj jasno odražati njihovo
              dejansko vsebino.
            </p>
            <p>
              Vsaka objava mora biti uvrščena v ustrezno kategorijo in
              podkategorijo, kot so opredeljene v aplikaciji in skladne s
              Pravilnikom o seznamih zvrsti dediščine in varstvenih usmeritvah
              (Uradni list RS, št. 102/10). Pri uvrščanju tem v ustrezno
              kategorijo si mora uporabnik prebrati opise posameznih kategorij v
              aplikaciji, da svoj zapis najprimerneje uvrsti.
            </p>
            <p>
              Po želji lahko uporabnik zapis opremi s ključnimi besedami
              (ključniki, tagi).
            </p>
            <p>Prepovedana je uporaba samo velikih črk (kričanje).</p>
            <p>Objave v aplikaciji morajo biti v slovenskem jeziku.</p>
            <p>
              Gradivo, ki ga uporabnik naloži v aplikacijo oz. v njej objavi, so
              'zapisi uporabnika'. Ti lahko vključujejo besedila, fotografije,
              videoposnetke, zvočne posnetke, povezave do drugih strani ipd.
            </p>
            <p>
              Da lahko upravljavec aplikacije te zapise uporablja in da so na
              voljo za ogled drugim uporabnikom aplikacije (registriranim in
              neregistriranim), uporabnik, ki je avtor zapisa, upravljavcu
              podeljuje materialne avtorske pravice, vezane na zapis, in sicer:
            </p>
            <p>
              časovno neomejeno, neizključno, prenosljivo, teritorialno
              neomejeno licenco z možnostjo podlicenciranja in brez tantiem – ta
              licenca/dovoljenje omogoča shranjevanje, prikazovanje,
              objavljanje, spreminjanje (predelavo), prevajanje vnesenih vsebin
              ter druge aktivnosti, ki so v skladu z namenom aplikacije.
              Upravljavec lahko te vsebine uporablja za promocijo aplikacije in
              svojih drugih dejavnosti na področju kulturne dediščine, jo
              reproducira, tiska, prenaša na druge, vključuje v nadaljnje
              projekte in sodelovanja, razširja po lastni presoji itd.
            </p>
            <p>
              Ko je vsebina, ki jo uporabnik vpiše v aplikacijo, enkrat potrjena
              s strani upravljavca in torej je 'zapis spomina' javno objavljen,
              uporabnik te vsebine več ne more izbrisati, to pravico si
              pridržuje upravljavec aplikacije.
            </p>

            <h3>4. ČLEN – Prepovedane vsebine, zavrnitev, dopolnitev</h3>
            <p>
              Prepovedane so objave, ki so žaljive, neprimerne, ki vsebujejo
              kletvice, ki bi spodbujale sovraštvo, diskriminacijo ali
              omalovaževanje posameznikov oziroma skupine na podlagi njihove
              rase, etnične pripadnosti, vere, invalidnosti, starosti,
              narodnosti, statusa, spolne usmerjenosti, spola, spolne identitete
              ali drugih lastnosti, ki so povezane s sistemsko diskriminacijo
              ali marginalizacijo.
            </p>
            <p>
              O primernosti oziroma neprimernosti objav odloča upravljavec, ki
              lahko objavo, ki jo smatra za neprimerno, zavrne.
            </p>
            <p>
              Ravno tako lahko upravljavec zavrne objavo, ki ni popolna, ne
              izkazuje koherentnosti, istovetnosti, berljivosti, smiselnosti ali
              resničnosti dejstev.
            </p>
            <p>
              Upravljavec samostojno odloča o tem, katere objave se popolnoma
              zavrne, katere pa pošlje avtorju zapisa v dopolnitev ali popravek
              objave. V slednjih primerih upravljavec avtorja kontaktira prek
              telefonske številke (če je navedena) ali elektronskega naslova.
            </p>
            <p>
              Upravljavec ima pravico moderirati ali v celoti odstraniti vse
              prispevke, ki niso v skladu s temi pravili.
            </p>
            <p>
              Upravljavec lahko blokira uporabniško ime oz. uporabnika in mu
              onemogoči dostop do računa, če ta namerno ali večkrat krši ta
              pravila uporabe.
            </p>
            <p>
              V aplikaciji je prepovedano oglaševanje z namenom prodaje
              proizvodov in storitev.
            </p>
            <p>
              Aplikacije ne sme uporabljati oseba, s katero je bil v preteklosti
              že prekinjen sporazum oz. ukinjen račun zaradi kršitev Pogojev
              uporabe ali pravilnikov aplikacije Zapisi spomina.
            </p>
            <p>
              Ravno tako aplikacije ne sme uporabljati oseba, ki ji veljavna
              zakonodaja prepoveduje uporabo aplikacije.
            </p>
            <p>
              Aplikacijo je prepovedano uporabljati za ravnanja ali objavljanja
              vsebin, ki:
            </p>
            <ul>
              <li>
                kršijo te pogoje ter druge pogoje in pravilnike, ki veljajo za
                uporabo aplikaicije;
              </li>
              <li>so nezakonite, zavajajoče, diskriminatorne ali goljufive;</li>
              <li>
                posegajo v pravice drugih oseb ali jih kršijo, vključno s
                pravicami intelektualne lastnine.
              </li>
            </ul>

            <p>
              V aplikacijo je prepovedano nalagati viruse ali druge zlonamerne
              kode ali storiti kar koli, kar bi lahko onemogočilo, preobremenilo
              ali oviralo pravilno delovanje in podobo aplikacije.
            </p>
            <p>
              Upravljavec ima pravico odstraniti ali omejiti dostop do
              uporabnikove vsebine, storitev ali informacij, če ugotovimo, da je
              tak ukrep razumno potreben za odpravo ali omilitev negativnih
              pravnih ali regulatornih posledic za Zavod Dobra pot.
            </p>

            <h3>5. ČLEN – Avtorske pravice in soglasja za uporabo podatkov</h3>
            <p>
              V kolikor uporabnik v aplikacijo naloži fotografije, potrjuje, da
              ima avtorske pravice za objavo fotografij v smislu, da je avtor
              fotografij sam oziroma, če ni sam avtor, da ima dovoljenje avtorja
              za nalaganje in objavo fotografij v aplikaciji Zapisi spomina.
            </p>
            <p>
              Če uporabnik v aplikacijo vnese podatke o osebah, ki so že
              preminule, ali spomine preminulih oseb, pri zapisu upošteva
              njihovo pravico do pietete, spoštovanja in lepega spomina ter
              jamči, da ima za to dovoljenje dedičev oz. ožjih svojcev te osebe.
            </p>
            <p>
              V kolikor uporabnik zapise opremi z zgolj reprezentativnimi,
              simboličnimi fotografijami, le-to ustrezno navede v zapisu, da se
              prepreči zavajanje bralcev.
            </p>
            <p>
              Uporabnik lahko v zapis vstavi hiperpovezave do drugih dokumentov,
              fotografij in posnetkov. Tudi v tem primeru jamči, da ima
              dovoljenje avtorja za vstavitev hiperpovezave.
            </p>
            <p>
              V kolikor se uporabnik odloči za vpis razširjenega nabora
              etnološko relevantnih podatkov o nosilcu izročila (ki je lahko sam
              ali druga oseba) z namenom podrobnejšega etnološkega zapisa,
              jamči, da ima dovoljenje ustrezne osebe za vpis teh
              podatkov/fotografij/zvočnih posnetkov/videoposnetkov/dokumentov.
            </p>
            <p>
              Enako velja za objavo glasbe – v aplikacijo se lahko naloži le z
              izrecnim dovoljenjem avtorja/-jev. Z objavo glasbe uporabnik
              jamči, da ima vsa ustrezna soglasja avtorja/-jev glasbe.
            </p>
            <p>
              V kolikor je ugotovljena kakršna koli kršitev avtorskih pravic ali
              kršitev varstva osebnih podatkov, je za to izključno odgovoren
              uporabnik, ki je te kršitve povzročil.
            </p>
            <p>
              Če je upravljavec aplikacije obveščen o sumu kršenja avtorskih
              pravic ali varstva osebnih podatkov, sporno vsebino odstrani brez
              vnaprejšnjega obvestila uporabniku.
            </p>

            <h3>6. ČLEN - Omejitve pri uporabi intelektualne lastnine</h3>
            <p>
              Če uporabnik uporablja vsebino, za katero ima pravice
              intelektualne lastnine Zavod Dobra pot, ki jih daje na voljo v
              svojih izdelkih (na primer slike, oblikovalske zasnove, videi in
              zvoki), Zavod Dobra pot obdrži vse pravice do te vsebine (vendar
              ne do vsebine uporabnika).
            </p>
            <p>
              Če uporabnik vsebino, ki je objavljena v aplikaciji, deli na
              drugih spletnih mestih, na družbenih omrežjih in podobno, mora to
              storiti z uporabo namenskih 'gumbov' za deljenje ali z delitvijo
              celotne povezave (url naslova), da se ohrani povezava do izvorne
              vsebine. Vsebin ni dovoljeno deliti na drugačne načine
            </p>

            <h3>7. ČLEN - Popust</h3>
            <p>
              Projektni partner upravljalca (aplikacije), Slovenski etnografski
              muzej (SEM), registriranim uporabnikom aplikacije omogoča 50 %
              popust na vstopnico za enkratni ogled razstav SEM v letu 2021.
              Popust posameznik pridobi tako, da se na recepciji SEM izkaže s
              potrdilom iz aplikacije (potrdilo je vsakemu registriranemu
              uporabniku na voljo zavihku »Moj profil«).
            </p>

            <h3>8. ČLEN - Spreminjanje Pogojev uporabe</h3>
            <p>
              Upravljavec aplikacije si pridržuje pravico do sprememb in
              dopolnitev teh Pogojev uporabe glede na izkazane potrebe, širitev
              uporabe aplikacije in morebitne spremembe zakonskih predpisov.
            </p>
            <p>
              Vse spremembe in dopolnitve Pogojev uporabe se objavijo v
              aplikaciji, kjer so javno dostopne. Spremenjeni Pogoji uporabe
              stopijo v veljavo z dnem objave v aplikaciji.
            </p>
            <p>
              Ob spremembi pogojev vsi registrirani uporabniki prejmejo
              elektronsko obvestilo o spremembi pogojev (od prejema tega
              obvestila se uporabniki ne morejo objaviti). V kolikor po prejemu
              tega elektronskega obvestila nadaljujejo z uporabo aplikacije, se
              smatra, da se s spremenjenimi pogoji strinjajo.
            </p>

            <h3>9. ČLEN - Obdobje veljavnosti, prekinitev sporazuma</h3>
            <p>Ta sporazum je sklenjen za nedoločen čas.</p>
            <p>
              Z uporabo aplikacije lahko uporabnik kadar koli preneha. Uporabnik
              lahko kadar koli prekine pogodbo po lastni volji tako, da željo po
              ukinitvi svojega računa sporoči na elektronski naslov upravljavca
              aplikacije (info@dobra-pot.si).
            </p>
            <p>
              Šteje se, da je sporazum z uporabnikom prenehal, če uporabnik ne
              poda svojega soglasja k spremembam in dopolnitvam teh Pogojev
              uporabe.
            </p>
            <p>
              Zavod Dobra pot si pridržuje pravico, da prekine ta sporazum in
              onemogoči dostop do uporabnikovega računa, če pride do kršitve teh
              Pogojev uporabe in če uporabnik krši pravice intelektualne
              lastnine drugih oseb ali je prekinitev sporazuma potrebna iz
              pravnih razlogov.
            </p>
            <p>
              O datumu in razlogih prekinitve Zavod Dobra pot obvesti
              uporabnika.
            </p>

            <h3>10. ČLEN - Omejitev odgovornosti</h3>
            <p>
              Izključena je odškodninska odgovornost Zavoda Dobra pot do
              uporabnika za 1) uporabo storitev, vključno z morebitnimi izpadi
              delovanja ali obdobji, ko aplikacija ni na voljo 2) informacije in
              vsebino, ki jo v aplikacijo vnesejo drugi uporabniki 3) način
              uporabe aplikacije s strani drugih uporabnikov, 4) vsakršno
              posredno, posledično ali posebno škodo.
            </p>

            <h3>11. ČLEN - Elektronska komunikacija</h3>
            <p>
              Obvestila in opozorila v zvezi z uporabo aplikacije se pošiljajo v
              besedilni obliki po elektronski pošti, po potrebi se sporočijo
              tudi po telefonu (če uporabnik navede telefonsko številko).
            </p>

            <h3>12. ČLEN – Reševanje sporov</h3>
            <p>
              Morebitne nesporazume in spore, ki izhajajo iz tega sporazuma si
              bosta uporabnik in upravljavec prizadevala rešiti sporazumno, če
              to ne bo mogoče, bo o sporu odločalo stvarno in krajevno pristojno
              sodišče.
            </p>

            <h3>13. ČLEN - Končne določbe</h3>
            <p>
              1. Če bi se izkazalo, da je katerakoli določba teh Pogojev uporabe
              iz kakršnihkoli razlogov neveljavna, nična ali neizvršljiva,
              ostanejo vse ostale določbe še naprej v veljavi.
            </p>
            <p>
              2. Za razlago določil teh Pogojev uporabe se uporabljajo določbe
              Zakona o avtorski in sorodnih pravicah, Obligacijskega zakonika in
              drugi veljavni predpisi Republike Slovenije.
            </p>

            <div class="mt-5">&nbsp;</div>
            <div class="mt-5">&nbsp;</div>
            <h2
                class="mt-5"
                id="pravilnik-o-varstvu-osebnih-podatkov"
                name="pravilnik-o-varstvu-osebnih-podatkov"
            >
              PRAVILNIK O VARSTVU OSEBNIH PODATKOV
            </h2>

            <h3>1. Upravljavec osebnih podatkov in kontaktni podatki</h3>
            <p>
              Ta pravila veljajo za obdelavo (uporabo) katerihkoli osebnih
              podatkov, ki jo izvaja Zavod Dobra pot (upravljavec) za namene
              upravljanja aplikacije Zapisi spomina ali druge aplikacije, ki se
              izvaja v imenu upravljavca.
            </p>
            <p>Podatki o upravljavcu:</p>
            <p>Zavod Dobra pot, zavod za kulturo in sonaravno delovanje</p>
            <p>sedež: Barka 31, 6217 Vremski Britof, Slovenija</p>
            <p>
              naslov za sprejemanje strank: Partizanska cesta 33b, 6210 Sežana,
              Slovenija
            </p>
            <p>Davčna številka: 10740309 (nismo zavezanci za DDV)</p>
            <p>Matična številka: 6824030000</p>
            <p>elektronski naslov: info@dobra-pot.si</p>

            <h3>2. Katere podatke o uporabnikih zbiramo in obdelujemo?</h3>
            <p>Zbiramo naslednje podatke:</p>
            <ul>
              <li>ime in priimek</li>
              <li>prikazno ime oz. psevdonim</li>
              <li>letnico rojstva</li>
              <li>
                ime in priimek zakonitega zastopnika mladoletnega uporabnika
              </li>
              <li>
                elektronski naslov zakonitega zastopnika mladoletnega uporabnika
              </li>
              <li>telefonsko številko (če jo uporabnik vnese, neobvezno)</li>
              <li>elektronski naslov</li>
              <li>profilna slika (če jo uporabnik naloži, neobvezno)</li>
              <li>
                eventuelno še: naslov, poklic/izobrazbo, hišno ime, regijo,
                izvorno regijo, opis od kje je dobil podatke (če uporabnik s
                temi podatki opremi svoj zapis)
              </li>
              <li>
                podatke o uporabi aplikacije (kliki na povezave, porabljen čas)
                in podatki glede odziva na e-poštna sporočila (ali je bilo
                sporočilo odprto, na katere povezave ste kliknili);
              </li>
            </ul>

            <p>
              Upravljavec zbira tudi elektronske naslove tistih uporabnikov, ki
              se v aplikaciji prijavijo za prejemanje elektronskih novic o
              kulturni dediščini. Elektronske novice, ki jih zavod pošilja,
              nimajo elementov neposrednega trženja, ampak z njimi upravitelj
              svoje naročnike in uporabnike obvešča o aktivnostih in projektih,
              o svojem delovanju na področju kulturne dediščine. Posameznik se
              lahko od prejemanja elektronskih novic o kulturni dediščini kadar
              koli odjavi.
            </p>

            <p>
              Poleg podatkov o uporabniku upravljavec aplikacije zbira in hrani
              tudi podatke o nosilcu spomina/pričevalcu (ki je lahko druga oseba
              od uporabnika aplikacije). Ti podatki se hranijo v arhivu
              upravljavca aplikacije in praviloma niso javno objavljeni, če jih
              zapisovalec/uporabnik ne razkrije v samem zapisu (besedilu
              objave). Izjema je regija, iz katere prihaja spomin, ki je javno
              prikazana ob vseh objavah.
            </p>
            <p>
              Podatke o nosilcu spomina upravljavec hrani v arhivu z namenom
              izvajanja strokovnih analiz in študij ter preverjanja pristnosti
              zapisov. Te podatke upravitelj lahko da na razpolago
              znanstvenikom, etnologom in drugim strokovnjakom za namen
              izvajanja zgodovinskih raziskav, etnoloških raziskav oziroma
              raziskav na področju kulturne dediščine, ki služijo splošni
              družbeni dobrobiti, napredku znanosti in razumevanja družbene
              zgodovine, tehnološkemu napredku, javnemu interesu.
            </p>
            <p>
              Če se upravitelj odloči podatke iz arhiva deliti z zgoraj
              navedenimi strokovnjaki, se ob tem prepriča o njihovi istovetnosti
              in z njimi sklene pogodbo, ki podrobno opredeljuje obseg in namen
              uporabe teh podatkov.
            </p>

            <h3>
              3. Prostovoljnost posredovanja podatkov in posledice
              neposredovanja
            </h3>
            <p>
              Posredovanje osebnih podatkov je prostovoljno. Osebnih podatkov
              uporabnik ni dolžan posredovati, vendar pa v primeru, da jih ne
              posreduje, ne more z Zavodom Dobra pot skleniti sporazuma (in
              posledično uporabljati aplikacije).
            </p>

            <h3>
              4. Kateri podatki, ki jih uporabnik vnese, so javno objavljeni v
              aplikaciji in kateri ne?
            </h3>
            <p>Javno objavljeni so naslednji podatki o uporabniku:</p>
            <ul>
              <li>prikazno ime oz. psevdonim</li>
              <li>profilna slika (če jo uporabnik naloži)</li>
              <li>opis uporabnika (če ga uporabnik vpiše)</li>
            </ul>

            <h3>5. Kako in komu posredujemo te podatke?</h3>

            <p>a. Dobavitelji</p>
            <p>
              Podatke in vsebino posredujemo dobaviteljem in ponudnikom
              storitev, ki podpirajo naše delovanje, na primer z zagotavljanjem
              storitev tehnične in programske infrastrukture, analiziranjem
              uporabe naših izdelkov, zagotavljanjem pravnega svetovanja,
              pošiljanjem e-sporočil, izvajanjem anket in podobno.
            </p>
            <p>b. Raziskovalci, etnologi, strokovne institucije</p>
            <p>
              Podatke in vsebino zagotavljamo raziskovalcem, akademikom in
              strokovnim institucijam za izvajanje etnoloških raziskav oziroma
              raziskav na področju kulturne dediščine z namenom uresničevanja
              splošne družbene dobrobiti, tehnološkega napredka, javnega
              interesa, zdravja in dobrega počutja.
            </p>
            <p>c. Organi pregona ali pravne zahteve</p>
            <p>
              Podatke po potrebi posredujemo organom pregona ali na podlagi
              pravnih zahtev.
            </p>
            <p>
              Zgoraj navedeni obdelovalci podatkov smejo osebne podatke
              obdelovati zgolj v okviru navodil in osebnih podatkov ne smejo
              obdelovati za lastne namene. Zavezani so, skupaj s svojimi
              zaposlenimi, k varovanju zaupnosti osebnih podatkov.
            </p>
            <p>
              Zgoraj navedeni obdelovalci podatkov ne iznašajo v tretje države
              (izven držav članic Evropskega gospodarskega prostora – to so
              članice EU ter Islandija, Norveška in Liechtenstein).
            </p>

            <h3>6. Pravna podlaga za obdelovanje podatkov</h3>
            <p>
              Osebne podatke obdelujemo na naslednjih pravnih podlagah: Uredba
              (EU) 2016/679 Evropskega parlamenta in Sveta z dne 27. aprila 2016
              o varstvu posameznikov pri obdelavi osebnih podatkov in o prostem
              pretoku takih podatkov (v nadaljevanju: Splošna uredba o varstvu
              podatkov, GDPR) in Zakon o varstvu osebnih podatkov (ZVOP-1-UPB1,
              Ur.l. RS, štev. 94/2007) in Pravilnik o varstvu osebnih podatkov
              Zavoda Dobra pot.
            </p>
            <h3>
              7. Kako se odzivamo na pravne zahteve ali preprečujemo škodo?
            </h3>
            <p>
              Do osebnih podatkov dostopamo, jih hranimo in posredujemo samo
              tistim uporabnikom, ki se izkažejo z ustrezno zakonsko podlago ali
              s pisno zahtevo oziroma privolitvijo posameznika, na katerega se
              podatki nanašajo.
            </p>
            <h3>
              8. Kako lahko uveljavljate svoje pravice na podlagi Splošne uredbe
              o varstvu podatkov ?
            </h3>
            <p>
              V skladu s Splošno uredbo o varstvu podatkov imate pravico, da
              dostopate do svojih podatkov, jih popravite, prenesete in
              izbrišete in zahtevate, da se izbrišejo. Prav tako imate pravico
              do ugovora in omejitve nekaterih vrst obdelave podatkov. Te
              pravice lahko uveljavljate, tako, da pišete na info@dobra-pot.si.
              To vključuje:
            </p>
            <p>
              • pravico do ugovora zoper obdelavo podatkov o vas pri našem
              izvajanju dejavnosti v javnem interesu oz. pri prizadevanju za
              naše zakonite interese ali interese tretjih oseb.
            </p>
            <h3>9. Hranjenje podatkov</h3>
            <p>
              Podatke hranimo tako dolgo, kot je potrebno za delovanje
              aplikacije.
            </p>
            <h3 id="politika-piskotkov"
                name="politika-piskotkov">10. Politika piškotkov</h3>
            <p>
              <b>Obvestilo o uporabi piškotkov</b><br/>
              To obvestilo je podano v skladu z Zakonom o elektronskih komunikacijah (ZEKom-1), ki ureja pravila glede
              uporabe piškotkov in podobnih tehnologij za shranjevanje podatkov ali pridobivanje dostopa do podatkov,
              shranjenih na uporabnikovem računalniku, pametnem telefonu ali drugi napravi.
            </p>

            <p>
              <b>Kaj so piškotki?</b><br/>
              Piškotki so majhne besedilne datoteke, ki jih aplikacija shrani v vaš računalnik ali mobilno napravo, ko
              odprete spletno mesto.<br/>
              Kaj je namen piškotkov?<br/>
              Piškotki se uporabljajo za shranjevanje informacij o tem, kako uporabljate aplikacijo Zapisi spomina, na
              primer, kdaj ste nazadnje obiskali spletno mesto aplikacije in kakšne informacije ste iskali, ko ste bili
              na strani. Te datoteke lahko prebere le naša aplikacija, dostopa nimajo druge osebe.<br/>
              Ta spletna stran uporablja Google Analytics, storitev spletne analitike, ki jo ponuja Google, Inc.
              (Google). Podatki, ki jih ta piškotek ustvari ob uporabi te spletne strani (vključno z vašim naslovom IP),
              bodo poslani Googlovim strežnikom in bodo tam shranjeni. Google bo te podatke uporabil za ovrednotenje
              vaše uporabe spletnega mesta, za zbiranje poročil o dejavnostih spletnih mest za upravitelje spletnih mest
              in za zagotavljanje drugih storitev, povezanih z dejavnostmi spletnega mesta in uporabe interneta. Google
              lahko te informacije pošlje tretjim osebam, če to zahteva zakonodaja ali če tretje osebe obdelujejo te
              podatke v imenu Googla. Google vašega IP-naslova nikoli ne bo povezal z drugimi podatki, ki jih ima
              Google. Z uporabo te strani pooblaščate Google, da obdeluje podatke, ki se nanašajo na vas, na način in za
              zgoraj navedene namene<br/>
              Pri uporabi piškotkov upravljavec aplikacije ne shranjuje in obdeluje osebnih podatkov uporabnika. Ravno
              tako ne uporablja elektronskih komunikacijskih omrežij za shranjevanje osebnih podatkov ali pridobitev
              dostopa do osebnih podatkov. Upravljavec aplikacije tehnično shranjuje podatke in dostopa do njih
              izključno za namen opravljanja oziroma lajšanja prenosa sporočila prek elektronskega komunikacijskega
              omrežja ter za zagotovitev delovanja aplikacije, tj. prijazno uporabniško izkušnjo za uporabnike.
              Upravljavec aplikacije uporablja piškotke seje za sledljivost uporabnika med brskanjem po aplikaciji.
              Sejni piškotki se izbrišejo iz računalnika uporabnika, ko le-ta zapre brskalnik. Sejni piškotki so
              ustvarjeni začasno in so izbrisani, ko uporabnik zapre okno brskalnika. Trajni ali shranjeni piškotki so
              piškotki, ki ostanejo shranjeni na uporabnikovi napravi. Ti piškotki se aktivirajo vsakič, ko uporabnik
              obišče spletno stran, ki je ustvarila ta konkretni piškotek.</p>


            <p><b>Katere piškotke uporabljamo?</b><br/>
              Vsi piškotki, ki jih uporablja spletno mesto aplikacije Zapisi spomina, so nujno potrebni za delovanje
              aplikacije. To so:<br/><br/>
              <b>_ga in _ga_E0NS1DBKZV</b>:<br/>
              spletna analitika s pomočjo programa Google Universal Analytics. Piškotek, ki se uporablja za sledenje
              obiskovalcem. Google Analytics identificira uporabnike z edinstvenim identifikatorjem imenovanim "client
              id". Ta id je skupaj z drugimi parametri shranjen v _ga piškotku.<br/><br/>
              <b>PH_HPXY_CHECK</b>;<br/>
              piškotek uporablja varnostni sistem za preprečevanje brute foce napadov in ne omogoča identifikacije
              posameznega uporabnika. Traja do konca seje (zaprtja brskalnika). sledenje obiskovalcem, grupiranje
              vedenja posameznega uporabnika<br/> <br/>
              <b>_gid;</b><br/>
              spletna analitika s pomočjo programa Google Universal Analytics. Piškotek, se uporablja za grupiranje
              obnašanja uporabnikov za vsakega uporabnika. https://policies.google.com/<br/><br/>
              Za identifikacijo uporabnika na Youtubu:<br/>
              <b>YSC<br/>
                VISITOR_INFO1_LIVE<br/>
                PREF<br/>
                GEUP</b><br/><br/>

              Za gostovanje multimedijske vsebine na Dropboxu:<br/>
              <b>__Host-ss<br/>
                __Host-js_csrf<br/>
                uc_session<br/>
                gvc<br/>
                locale<br/>
                t<br/>
              </b>
            </p>
            <p>
              <b>Nastavitve piškotkov</b><br/>
              Uporabniki se lahko samostojno odločite, ali želite omogočiti uporabo piškotkov. Nastavitve za piškotke
              lahko nadzirate in spreminjate v svojem spletnem brskalniku. Če piškotkov ne sprejmete, si ne boste mogli
              ogledovati vsebin spletnega mesta aplikacije Zapisi spomina.
            </p>
          </div>
        </article>
        <br/><br/><br/><br/><br/><br/><br/><br/>
      </b-col>
      <b-col lg="4">
        <Sidebar></Sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";

import Sidebar from "@/components/Sidebar";

export default {
  name: "TermsAndConditions",
  components: {
    Sidebar
  },
  data() {
    return {
      users: []
    };
  },
  metaInfo() {
    return {
      title: "Pogoji uporabe"
    };
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
<style type="text/css" scoped>
* {
  color: #6d5a3f;
}

h2 {
  font-size: 18px;
  margin: 40px 0px;
}

h3 {
  font-size: 15px;
  margin: 20px 0px;
}

p {
  margin-bottom: 10px !important;
  font-size: 12px;
  line-height: 18px;
}

ul {
  list-style-type: circle;
}

li {
  font-size: 12px;
}
</style>
